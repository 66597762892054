<template>
  <div class="all-service-types">
    <AdminTable title="All Service Types" :list="serviceTypeList" :fields="fields" :loading="loading"
    @update-list="update"/>
  </div>
</template>

<script>
import AdminTable from '@/components/admin/AdminTable'
import { mapState } from 'vuex'

import { serviceTypeService } from '@/services/admin'

export default {
  name: 'AdminAllServiceTypes',
  components: {
    AdminTable,
  },
  data() {
    return {
      serviceTypeList: [],
      loading: false,
    }
  },
  computed: {
    fields() {
      return [
        { key: 'id', label: 'ID' },
        { key: 'name', label: 'Name' },
      ]
    }
  },
  async beforeMount() {
    await this.update()
  },
  methods: {
    async update() {
      this.loading = true
      this.serviceTypeList = await serviceTypeService.getServiceTypes()
      this.$store.commit('adminServiceTypeStore/setServiceTypes', this.serviceTypeList)
      this.loading = false
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
